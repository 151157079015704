import jwtDecode from 'jwt-decode';
import { AppRoutes, Keys } from '@/constants/general';
import { JwtDecodedContent } from '@/interfaces/general';

export class AuthToken {
  public get authorizationString() {
    const token = this.getToken();
    return `Bearer ${token}`;
  }

  get isValid(): boolean {
    return !this.isExpired;
  }

  get userId() {
    const token = this.getToken() || '';
    const { id } = jwtDecode<JwtDecodedContent>(token);
    return id;
  }

  get checkLoginAttempts() {
    return Number(window.sessionStorage.getItem(Keys.LOGIN_ATTEMPTS_KEY)) >= 3;
  }

  addLoginAttempt() {
    const totalAttempts = Number(window.sessionStorage.getItem(Keys.LOGIN_ATTEMPTS_KEY)) + 1;
    window.sessionStorage.setItem(Keys.LOGIN_ATTEMPTS_KEY, totalAttempts.toString());
  }

  resetLoginAttempts() {
    window.sessionStorage.setItem(Keys.LOGIN_ATTEMPTS_KEY, '0');
  }

  tokenExpirationDate() {
    const token = this.getToken() || '';
    const date = this.expiresAt(token);
    return date.valueOf();
  }

  isExpired(): boolean {
    const token = this.getToken();
    const { id } = jwtDecode<JwtDecodedContent>(token);
    const isExpired = new Date() > this.expiresAt(token) || !id;
    if (isExpired) {
      this.logout();
    }
    return isExpired;
  }

  hasValidToken() {
    const token = this.getToken();
    return !!token && !this.isExpired();
  }

  storeToken(token: string, stayLogged = false) {
    if (!stayLogged) {
      window.sessionStorage.setItem(Keys.TOKEN_STORAGE_KEY, token);
      localStorage.removeItem(Keys.STAY_SIGN_IN);
      return;
    }
    localStorage.setItem(Keys.TOKEN_STORAGE_KEY, token);
    localStorage.setItem(Keys.STAY_SIGN_IN, 'true');
  }

  logout(preventRedirect = false) {
    window.sessionStorage.removeItem(Keys.TOKEN_STORAGE_KEY);
    localStorage.removeItem(Keys.TOKEN_STORAGE_KEY);

    if (!preventRedirect) {
      // Redirect to sign-in page
      window.location.replace(`/app${AppRoutes.SignIn}`);
    }
  }

  checkStaySignIn() {
    return localStorage.getItem(Keys.STAY_SIGN_IN) === 'true';
  }

  private expiresAt(token: string): Date {
    const { exp } = jwtDecode<JwtDecodedContent>(token);
    return new Date(exp * 1000);
  }

  get isTokenSavedInSession() {
    const sessionToken = window.sessionStorage.getItem(Keys.TOKEN_STORAGE_KEY);
    return !!sessionToken;
  }

  private getToken() {
    if (typeof window !== 'object') return '';

    const sessionToken = window.sessionStorage.getItem(Keys.TOKEN_STORAGE_KEY);
    const token = localStorage.getItem(Keys.TOKEN_STORAGE_KEY);
    return sessionToken ?? token ?? '';
  }
}
